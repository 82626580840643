<template>
  <div>
    <el-button @click="dialogVisible = true">添加报表</el-button>
    <el-table size="small" :data="selectedList" style="width: 100%;">
      <el-table-column prop="modelName" label="报表名称" />
      <el-table-column prop="updatedAt" label="报表更新日期" />
      <el-table-column prop="prop" label="操作" width="140px">
        <template slot-scope="scoped">
          <el-button type="text" :disabled="selectedList[0].modelId === scoped.row.modelId" @click="moveUp(scoped.row)">上移</el-button>
          <el-button type="text" :disabled="selectedList[selectedList.length - 1].modelId === scoped.row.modelId" @click="moveDown(scoped.row)">下移</el-button>
          <el-button type="text" style="color: rgb(232, 49, 47);" @click="onDel(scoped.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="添加报表"
      class="dialog"
      :visible.sync="dialogVisible"
      width="720px"
      destroy-on-close
      @open="open"
    >
      <div>
        <el-input
          v-model="reportForm.modelName"
          v-debounce="[onSearch, 'input']"
          clearable
          placeholder="请输入报表名称"
          style="margin-bottom: 10px;"
          @clear="onSearch"
        />
        <el-table
          ref="multipleTable"
          v-loading="reportLoading"
          size="mini"
          :data="tableData"
          height="400px"
          class="table"
          border
          :row-key="row => row.modelId"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            align="center"
            :reserve-selection="true"
            width="50"
          />
          <el-table-column prop="modelName" label="报表名称" />
          <el-table-column
            prop="coverUrl"
            label="报表封面"
            width="120px"
            align="center"
          >
            <template slot-scope="scoped">
              <el-image
                fit="cover"
                style="width: 80px; height: 30px;"
                :src="scoped.row.coverUrl"
                :preview-src-list="srcList"
              />
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-wrap">
          <el-pagination
            center
            :current-page="pagination.pageNo"
            :total="pagination.total"
            :page-size="pagination.pageSize"
            :page-sizes="pagination.sizes"
            :layout="pagination.layout"
            :hide-on-single-page="false"
            background
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onReportSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
export default {
  name: 'ReportFormSelect',
  mixins: [paginationMixin],
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      dialogVisible: false,
      multipleSelection: [],
      reportLoading: false,
      reportForm: {
        modelName: ''
      },
      tableData: []
    }
  },
  computed: {
    srcList() {
      return this.tableData?.map(item => item.coverUrl)
    },
    selectedList: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 搜索
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    moveUp(row) {
      this.selectedList.some((item, index) => {
        if (item.modelId === row.modelId) {
          this.selectedList.splice(index, 1)
          this.selectedList.splice(index - 1, 0, item)
          return true
        }
      })
    },
    moveDown(row) {
      this.selectedList.some((item, index) => {
        if (item.modelId === row.modelId) {
          this.selectedList.splice(index, 1)
          this.selectedList.splice(index + 1, 0, item)
          return true
        }
      })
    },
    onDel(row) {
      this.selectedList = this.selectedList.filter(item => item.modelId !== row.modelId)
    },
    open() {
      this.multipleSelection = this.selectedList
      this.getDataList()
    },
    // 提交
    onReportSubmit() {
      this.dialogVisible = false
      this.selectedList = this.multipleSelection
    },
    // 获取报表列表
    async getDataList() {
      try {
        this.reportLoading = true
        const params = this.getParams(this.reportForm)
        const { data, totalCount } = await this.$axios.get(this.$API.getSmartReportFormList, { params })
        this.tableData = data
        this.multipleSelection?.map(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true)
        })
        this.pagination.total = totalCount
        this.reportLoading = false
      } catch (error) {
        this.$message.error(error.message)
        this.reportLoading = false
      }
    }
  }
}
</script>
