<template>
  <!-- 报表配置管理 -->
  <div class="page-main">
    <!-- 功能按钮区 -->
    <div class="search-wrapper">
      <el-form
        inline
        label-suffix="："
        class="form-wrap"
        @submit.native.prevent
      >
        <el-form-item label="服务名称">
          <el-input
            v-model="search.dataServiceTitle"
            v-debounce="[onSearch, 'input']"
            clearable
            placeholder="请输入服务名称"
            @clear="onSearch"
          />
        </el-form-item>
        <el-form-item label="服务状态">
          <el-select
            v-model="search.isPublish"
            clearable
            placeholder="请选择服务状态"
            @change="onSearch"
          >
            <el-option label="已发布" :value="true" />
            <el-option label="未发布" :value="false" />
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button icon="el-icon-plus" @click="handelNewActivity">新建</el-button>
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @sort-change="onSortChange"
    >
      <el-table-column prop="title" label="报表服务名称" />
      <el-table-column
        prop="isPublish"
        align="center"
        width="100px"
        label="服务状态"
      >
        <template slot-scope="scoped">
          {{ scoped.row.isPublish ? '已发布' : '未发布' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="publishAt"
        align="center"
        width="160px"
        label="发布日期"
      />
      <el-table-column
        prop="creatorName"
        align="center"
        width="80px"
        label="创建人"
      />
      <el-table-column
        prop="serviceViewCount"
        width="80px"
        align="center"
        label="浏览量"
      />
      <el-table-column label="操作" width="300">
        <template slot-scope="scoped">
          <el-button size="mini" @click="onCheck(scoped.row)"> 查看 </el-button>
          <el-button
            size="mini"
            type="primary"
            :disabled="scoped.row.isPublish"
            @click="onEdit(scoped.row)"
          >
            编辑
          </el-button>
          <el-button type="danger" size="mini" @click="onDel(scoped.row)"> 删除 </el-button>
          <el-button
            v-if="!scoped.row.isPublish"
            type="success"
            size="mini"
            @click="changePublishStatus(scoped.row, true)"
          >
            发布
          </el-button>
          <el-button
            v-else
            type="warning"
            size="mini"
            @click="changePublishStatus(scoped.row, false)"
          >
            取消发布
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
export default {
  name: 'ReportConfigManagement',
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        dataServiceTitle: '',
        isPublish: ''
      },
      loading: false,
      tableData: []
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    // 获取列表数据
    async getDataList() {
      try {
        this.loading = true
        const params = this.getParams(this.search)
        const { data, totalCount } = await this.$axios.get(this.$API.dataServiceQueryList, { params })
        this.tableData = data
        this.pagination.total = totalCount
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 表单提交
    editFormSubmit() {},
    // 搜索
    queryData() {
      this.pagination = this.$options.data().pagination
      this.getDataList()
    },
    handelNewActivity(row) {
      this.$router.push({
        name: 'ChartNewActivity',
        query: {
          id: row.userId
        }
      })
    },
    // 编辑
    onEdit(row) {
      this.$router.push({
        name: 'ChartNewActivity',
        query: {
          id: row.id
        }
      })
    },
    // 查看
    onCheck(row) {
      this.$router.push({
        name: 'ChartActivityDetail',
        query: {
          id: row.id
        }
      })
    },
    // 修改发布状态
    async changePublishStatus({ id: dataServiceId }, publishStatus) {
      try {
        const params = {
          publishStatus,
          dataServiceId
        }
        await this.$axios({
          method: 'post',
          url: this.$API.changePublishStatus,
          params
        })
        let message = `${publishStatus ? '发布' : '取消'}成功`
        this.$message.success(message)
        this.getDataList()
      } catch (error) {
        console.log('error: ', error)
        this.$message.error(error.message)
      }
    },
    // 删除
    async onDel({ id: dataServiceId }) {
      try {
        const params = {
          dataServiceId
        }
        await this.$axios({
          method: 'post',
          url: this.$API.dataServiceDel,
          params
        })
        this.$message.success('删除成功')
        this.getDataList()
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    }
  }
}
</script>
<style lang="scss" scoped>
// scss
.search-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .title {
    font-size: 22px;
    font-weight: 500;
  }
}
.handle-btn {
  margin-bottom: 10px;
}
.textarea {
  width: 400px;
}
.pagination-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
