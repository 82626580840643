<template>
  <div class="activity-detail">
    <div class="wrap">
      <el-form :model="detail" label-suffix="：">
        <el-form-item label="服务名称">
          {{ detail.title }}
        </el-form-item>
        <el-form-item label="服务状态">
          <span v-if="detail.isPublish"> 已发布 </span>
          <span v-else> 未发布 </span>
        </el-form-item>
        <el-form-item label="服务类型">
          {{ detail.relationDataType === 1 ? '数据报告' : detail.relationDataType === 2 ? '数据报表' : '' }}
        </el-form-item>
        <el-form-item label="服务简介">
          {{ detail.intro }}
        </el-form-item>
      </el-form>
    </div>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="服务内容" name="first">
        <div v-html="detail.content" />
      </el-tab-pane>
      <el-tab-pane v-if="detail.relationDataType === 2" label="相关报表" name="second">
        <el-table border :data="detail.smartModelInfoVOS" style="width: 100%;">
          <el-table-column prop="modelName" label="报表名称" />
          <el-table-column prop="categoryName" label="报表分类" />
          <el-table-column prop="status" label="报表状态">
            <template slot-scope="{ row: { status } }">
              {{ status === 0 ? '未发布' : status === 1 ? '预发' : status === 2 ? '已发布' : '' }}
            </template>
          </el-table-column>
          <el-table-column prop="createdAt" label="创建日期" />
          <el-table-column prop="intro" label="简介" />
          <el-table-column prop="showType" label="类型">
            <template slot-scope="scoped">
              {{ (scoped.row.showType && showTypeText(scoped.row.showType).value) || '' }}
            </template>
          </el-table-column>
          <el-table-column prop="coverUrl" label="封面">
            <template slot-scope="{ row: { coverUrl } }">
              <el-image
                style="width: 100px; height: 100px;"
                :src="coverUrl"
                fit="cover"
                :preview-src-list="previewSrcList"
              />
            </template>
          </el-table-column>
          <el-table-column prop="allViewCount" label="浏览量" />
          <el-table-column prop="collectCount" label="收藏数量" />
        </el-table>
      </el-tab-pane>
      <el-tab-pane v-if="detail.relationDataType === 1" label="相关报告" name="second">
        <el-table border :data="detail.reportVOS" style="width: 100%;">
          <el-table-column prop="reportName" label="报告名称" />
          <el-table-column prop="status" label="报告状态">
            <template slot-scope="{ row: { status } }">
              {{ status === 0 ? '未发布' : status === 1 ? '预发' : status === 2 ? '已发布' : '' }}
            </template>
          </el-table-column>
          <el-table-column prop="updatedAt" label="更新日期" />
          <!-- <el-table-column prop="intro" label="简介" /> -->
          <!-- <el-table-column prop="allViewCount" label="浏览量" /> -->
          <!-- <el-table-column prop="collectCount" label="收藏数量" /> -->
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="访问记录" name="third">
        <el-table v-loading="loading" border :data="accessList">
          <el-table-column prop="userName" width="200px" label="姓名" />
          <el-table-column prop="userMobile" width="200px" label="手机号" />
          <el-table-column prop="accessDateTime" width="200px" label="最新访问时间" />
          <el-table-column prop="accessFromType" width="200px" label="访问渠道">
            <template slot-scope="scoped">
              {{ scoped.row.accessFromType === 1 ? '数据服务推送' : '' }}
            </template>
          </el-table-column>
          <el-table-column prop="orgName" label="组织名称" />
        </el-table>
        <div class="pagination-wrap">
          <el-pagination
            center
            :current-page="pagination.pageNo"
            :total="pagination.total"
            :page-size="pagination.pageSize"
            :page-sizes="pagination.sizes"
            :layout="pagination.layout"
            :hide-on-single-page="false"
            background
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          />
        </div>
      </el-tab-pane>
    </el-tabs>

    <footer-bottom-box class="footer-bottom-box">
      <el-button @click="$router.back()">取消</el-button>
      <el-button type="primary" @click="toEdit">编辑</el-button>
      <el-button v-if="!detail.isPublish" type="primary" @click="changePublishStatus(true)">发布</el-button>
      <el-button v-else @click="changePublishStatus(false)">取消发布</el-button>
    </footer-bottom-box>
  </div>
</template>

<script>
import { showTypeText } from '../utils'
import paginationMixin from '@/mixins/pagination'
export default {
  name: 'ActivityDetail',
  mixins: [paginationMixin],
  data() {
    return {
      detail: {},
      data: [],
      accessList: [],
      activeName: 'first',
      loading: false
    }
  },
  computed: {
    showTypeText,
    previewSrcList() {
      return this.detail.smartModelInfoVOS
        .map(item => {
          return item.coverUrl
        })
        .filter(v => v)
    }
  },
  mounted() {
    this.getDetail()
    this.getDataList()
  },
  methods: {
    // 获取详情
    async getDetail() {
      try {
        const params = {
          dataServiceId: this.$route.query.id
        }
        const res = await this.$axios.get(this.$API.dataServiceDetail, { params })
        this.detail = res
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 修改发布状态
    async changePublishStatus(publishStatus) {
      try {
        const params = {
          publishStatus,
          dataServiceId: this.$route.query.id
        }
        await this.$axios({
          method: 'post',
          url: this.$API.changePublishStatus,
          params
        })
        let message = publishStatus ? '发布' : '取消' + '成功'
        this.$message.success(message)
        this.getDetail()
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 获取访问记录
    async getDataList() {
      try {
        this.loading = true
        const params = this.getParams({
          dataServiceId: this.$route.query.id
        })
        const { data, totalCount } = await this.$axios.get(this.$API.dataServiceQueryAccessList, { params })
        this.accessList = data
        this.pagination.total = totalCount
      } catch (error) {
        this.$message.error(error)
      } finally {
        this.loading = false
      }
    },

    toEdit() {
      this.$router.push({
        name: 'ChartNewActivity',
        query: {
          id: this.$route.query.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.activity-detail {
  .wrap {
    border: 1px solid #ddd;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .footer-bottom-box {
    text-align: right;
  }
  .pagination-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
