<template>
  <div class="new-activity">
    <el-row :gutter="10">
      <el-col :span="16">
        <el-form
          ref="form"
          :model="form"
          label-suffix="："
          label-width="100px"
        >
          <el-form-item label="服务名称">
            <el-input v-model.trim="form.title" placeholder="请输入标题（20个字以内）" :maxlength="200" />
          </el-form-item>
          <el-form-item label="服务简介">
            <el-input v-model.trim="form.intro" placeholder="请输入简介（50个字以内）" :maxlength="500" />
          </el-form-item>
          <el-form-item label="服务内容">
            <EditingTools v-model="form.content" />
          </el-form-item>
          <el-form-item label="相关报表">
            <div>
              <el-radio-group v-model="form.relationDataType">
                <el-radio :label="2">数据报表</el-radio>
                <el-radio :label="1">数据报告</el-radio>
              </el-radio-group>
            </div>
            <ReportFormSelect v-if="form.relationDataType === 2" v-model="form.smartModelInfoVOS" class="select-com" />
            <ReportSelect v-if="form.relationDataType === 1" v-model="form.reportVOS" class="select-com" />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <footer-bottom-box class="bottom-box">
      <el-button @click="$router.back()">取消</el-button>
      <el-button type="primary" @click="handleSubmit">提交</el-button>
      <el-button v-if="$route.query.id && form.isPublish" @click="changePublishStatus(false)">取消发布</el-button>
      <el-button v-else type="primary" @click="changePublishStatus(true)">发布</el-button>
    </footer-bottom-box>
  </div>
</template>

<script>
import ReportFormSelect from './components/ReportFormSelect'
import ReportSelect from './components/ReportSelect'

export default {
  name: 'NewActivity',
  components: {
    ReportFormSelect,
    ReportSelect
  },
  data() {
    return {
      form: {
        title: '',
        intro: '',
        content: '',
        relationDataIds: [],
        relationDataType: 2,
        id: ''
      },
      options: [],
      reportDialog: false
    }
  },
  mounted() {
    const { id } = this.$route.query
    if (id) {
      this.getDetail(id)
    }
  },
  methods: {
    // 获取详情
    async getDetail(dataServiceId) {
      try {
        const params = {
          dataServiceId
        }
        const res = await this.$axios.get(this.$API.dataServiceDetail, { params })
        if (res.relationDataType === 1) {
          res.smartModelInfoVOS = res.smartModelInfoVOS || []
        } else if (res.relationDataType === 2) {
          res.reportVOS = res.reportVOS || []
        }
        this.form = res
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 修改发布状态
    async changePublishStatus(publishStatus) {
      try {
        const reslove = await this.submit()
        if (reslove) {
          const params = {
            publishStatus,
            dataServiceId: this.$route.query.id || this.form.id
          }
          const res = await this.$axios({
            method: 'post',
            url: this.$API.changePublishStatus,
            params
          })
          if (res) {
            let message = publishStatus ? '发布' : '取消' + '成功'
            this.$message.success(message)
            this.$router.back()
          }
        }
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 提交
    async submit() {
      const params = Object.assign({}, this.form)
      if (params.relationDataType === 2) {
        if (params.smartModelInfoVOS?.length === 0) {
          this.$message.error('请选择相关报表')
          return
        }
        params.relationDataIds = params.smartModelInfoVOS?.map(item => item.modelId).join(',')
      } else if (params.relationDataType === 1) {
        if (params.reportVOS?.length === 0) {
          this.$message.error('请选择相关报告')
          return
        }
        params.relationDataIds = params.reportVOS?.map(item => item.reportId).join(',')
      }
      delete params.smartModelInfoVOS
      delete params.reportVOS

      try {
        const res = await this.$axios.post(this.$API.dataServiceSaveOrUpdate, params)
        this.form.id = res
        return res
      } catch (error) {
        console.log('error: ', error)
        this.$message.error(error.message)
      }
    },
    async handleSubmit() {
      try {
        const res = await this.submit()
        if (res) {
          this.$message.success('保存成功')
          this.$router.back()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.new-activity {
  .bottom-box {
    text-align: center;
  }
  :deep(.select-com) {
    .dialog {
      .el-dialog__body {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .pagination-wrap {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }
  }
}

</style>
